import { loadSentry } from '@/domains/core/observability/loadSentry';
import logger from '@/domains/core/observability/logger';

export const logIntoSentry = (
  error: Error = new Error('!undefined error!'),
  page:
    | 'addToCart'
    | 'cancelRemoveProductFromCart'
    | 'cancelRemoveProductFromSavedCart'
    | 'cartCount'
    | 'changeProductQuantity'
    | 'changeSavedProductQuantity'
    | 'getCurrentCart'
    | 'getCurrentSavedCart'
    | 'shareKeyRetrieval'
    | 'removeProductFromCart'
    | 'removeProductFromSavedCart'
    | 'savedCartCount'
    | 'saveFurtherInformation'
    | 'saveProduct'
    | 'unsaveProduct'
    | 'unsaveAllProducts'
    | 'cartOverview'
    | 'getFreeDeliveryFees'
    | 'asideCartRetrieval'
    | 'asideCartItemEdition'
    | 'asideCartItemAddition'
    | 'asideCartItemDeletion'
    | 'moveItemToMain'
    | 'mainCartRetrieval'
    | 'mainCartItemEdition'
    | 'mainCartItemAddition'
    | 'mainCartItemDeletion'
    | 'moveItemToAside'
    | 'mainCartToPDF',
  scopeTag = 'shoppingCart',
) => {
  loadSentry()
    .then((Sentry) => {
      Sentry.withScope((scope) => {
        scope.setTag(scopeTag, page);
        Sentry.captureException(error);
      });
    })
    .catch(() => logger.error(error));
};
